import {
  apiShoppingCart,
  apiShoppingCartAdd,
  apiShoppingCartAddItem,
  apiShoppingCartAddMemo
} from 'services/shoppingCart/shoppingCartService'
import { homeWeeklyEditQty } from 'actions/home/homeWeeklyTrendAction'
import { homeBuyAgainEditQty } from 'actions/home/homeBuyAgainAction'
import { productSignatureEditQty } from 'actions/home/homeProductSignatureAction'
import { getFilter } from 'actions/product/productAction'
import {
  FETCH_SHOPPING_CART,
  RECEIVE_SHOPPING_CART,
  RECEIVE_SHOPPING_CART_ID,
  UPDATE_QUANTITY_SHOPPING_CART,
  EDIT_QTY_SEARCH_PRODUCT,
  EDIT_QUANTITY_SHOPPING_CART,
  ADD_ITEM_SHOPPING_CART,
  FAILED_SHOPPING_CART,
  CHANGE_CURRENT_PARAM_SHOPPING_CART,
  COUNT_SUB_TOTAL_SHOPPING_CART,
  RECEIVE_SHOPPING_CART_MEMO,
  UPDATE_STATE_SHOPPING_CART
} from '../types'

const fetch = () => {
  return {
    type: FETCH_SHOPPING_CART
  }
}

const receive = (response) => {
  return {
    type: RECEIVE_SHOPPING_CART,
    payload: {
      list: response && response.data && response.data.length > 0 ? response.data : [],
      meta: response && response.meta ? response.meta : []
    }
  }
}

const updateState = (payload) => {
  return {
    type: UPDATE_STATE_SHOPPING_CART,
    payload
  }
}

const receiveMemo = (response) => {
  return {
    type: RECEIVE_SHOPPING_CART_MEMO,
    payload: {
      meta: response && response.meta ? response.meta : []
    }
  }
}

const countSubTotal = () => {
  return {
    type: COUNT_SUB_TOTAL_SHOPPING_CART
  }
}

const receiveShoppingCartAdd = (response) => {
  return {
    type: RECEIVE_SHOPPING_CART_ID,
    payload: {
      data: response && response.data,
      meta: response && response.meta ? response.meta : []
    }
  }
}

const receiveShoppingCartAddItem = (response) => {
  return {
    type: ADD_ITEM_SHOPPING_CART,
    payload: {
      data: response && response.data,
      meta: response && response.meta ? response.meta : []
    }
  }
}

const updateQty = (response) => {
  return {
    type: UPDATE_QUANTITY_SHOPPING_CART,
    payload: {
      item: response.item
    }
  }
}

const editQty = (response) => {
  return {
    type: EDIT_QUANTITY_SHOPPING_CART,
    payload: {
      item: response
    }
  }
}

const setCurrentParam = (params) => {
  return {
    type: CHANGE_CURRENT_PARAM_SHOPPING_CART,
    payload: {
      data: params
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_SHOPPING_CART,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const getShoppingCart = () => async (dispatch) => {
  dispatch(fetch())
  const response = await apiShoppingCart()
  if (response && response.success && response.data) {
    dispatch(receive(response))
    dispatch(countSubTotal())
  } else {
    dispatch(failed(response))
  }
}

const shoppingCartAddItem = (params, stateName, query) => async (dispatch) => {
  dispatch(fetch())
  dispatch(setCurrentParam(params))
  dispatch(updateQty({
    item: params.item
  }))
  const response = await apiShoppingCartAddItem(params)
  if (response && response.success && response.data) {
    dispatch(homeWeeklyEditQty(params))
    dispatch(homeBuyAgainEditQty(params))
    dispatch(productSignatureEditQty(params))
    dispatch(getShoppingCart())
    if (stateName === 'searchProduct') {
      dispatch(getFilter(query))
    } else if (stateName === 'searchProduct') {
      dispatch(getFilter(query))
    }
    dispatch(receiveShoppingCartAddItem(response))
  } else {
    dispatch(setCurrentParam({}))
    dispatch(failed(response))
  }
}

const shoppingCartEditQty = (params) => async (dispatch) => {
  try {
    const response = await apiShoppingCartAddItem(params)
    if (response && response.success) {
      dispatch(editQty(params))
      dispatch(countSubTotal())
    }
  } catch (error) {
    dispatch(failed(error))
  }
}

const shoppingCartEditMemo = (params) => async (dispatch) => {
  try {
    const response = await apiShoppingCartAddMemo(params)
    if (response && response.success) {
      dispatch(receiveMemo())
    }
  } catch (error) {
    dispatch(failed(error))
  }
}

const shoppingCartAdd = () => async (dispatch) => {
  dispatch(fetch())
  const response = await apiShoppingCartAdd()
  if (response && response.success && response.data) {
    dispatch(receiveShoppingCartAdd(response))
  } else {
    dispatch(failed(response))
  }
}

const editQtySearchProduct = (response) => {
  return {
    type: EDIT_QTY_SEARCH_PRODUCT,
    payload: {
      item: response
    }
  }
}

const editQuantitySearchProduct = (data) => async (dispatch) => {
  try {
    const response = await apiShoppingCartAddItem(data)
    if (response && response.success) {
      dispatch(editQtySearchProduct(data))
      dispatch(getShoppingCart())
    }
  } catch (error) {
    dispatch(failed(error))
  }
}

export {
  getShoppingCart,
  shoppingCartAdd,
  shoppingCartAddItem,
  shoppingCartEditQty,
  shoppingCartEditMemo,
  editQuantitySearchProduct,
  updateState
}
