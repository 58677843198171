import Router from 'next/router'
import {
  searchProductWithPagination,
  findAll,
  getByProductCode
} from 'services/product/productService'
import {
  apiShoppingCartAddItem
} from 'services/shoppingCart/shoppingCartService'
import { change } from 'redux-form'
import { call } from 'utils/yield'
import { getProductStat } from 'actions/product/userFavAction'
// eslint-disable-next-line import/no-cycle
import {
  FETCH_PRODUCT,
  RECEIVE_ITEM_PRODUCT,
  RECEIVE_PRODUCT,
  RECEIVE_SEARCH_PRODUCT,
  CLEAN_CURRENT_ITEM_PRODUCT,
  UPDATE_STATE_PRODUCT,
  EDIT_QUANTITY_PRODUCT,
  FAILED_PRODUCT
} from '../types'

const fetch = () => {
  return {
    type: FETCH_PRODUCT
  }
}

const receive = (response) => {
  return {
    type: RECEIVE_PRODUCT,
    payload: {
      list: response && response.data && response.data.length > 0 ? response.data : [],
      meta: response && response.meta ? response.meta : []
    }
  }
}

const receiveSearchProduct = (response) => {
  return {
    type: RECEIVE_SEARCH_PRODUCT,
    payload: {
      list: response && response.data && response.data.length > 0 ? response.data : [],
      meta: response && response.meta ? response.meta : []
    }
  }
}

const receiveItem = (response) => {
  return {
    type: RECEIVE_ITEM_PRODUCT,
    payload: {
      currentItem: response && response.data ? response.data : {}
    }
  }
}

const editQuantity = (response, product) => {
  return {
    type: EDIT_QUANTITY_PRODUCT,
    payload: {
      data: response || {},
      product: product || {}
    }
  }
}

const cleanCurrentItem = () => {
  return {
    type: CLEAN_CURRENT_ITEM_PRODUCT,
    payload: {}
  }
}

const failed = (error) => {
  return {
    type: FAILED_PRODUCT,
    payload: {
      data: error.data,
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const updateState = (payload) => {
  return {
    type: UPDATE_STATE_PRODUCT,
    payload
  }
}

const getFilter = (params) => async (dispatch) => {
  try {
    dispatch(fetch())
    const response = await searchProductWithPagination(params)
    if (response.success) {
      dispatch(receiveItem(response))
      return response.data
    }
    dispatch(failed(response))
  } catch (error) {
    dispatch(failed(error))
  }
}

const getSearchProduct = (params) => async (dispatch) => {
  try {
    dispatch(fetch())
    const response = await searchProductWithPagination(params)
    if (response.success) {
      dispatch(receiveSearchProduct(response))
      return response.data
    }
    dispatch(failed(response))
  } catch (error) {
    dispatch(failed(error))
  }
}

const getProductByCode = (productCode, userId) => async (dispatch) => {
  try {
    dispatch(fetch())
    const response = await getByProductCode(productCode)
    if (response.success) {
      dispatch(getProductStat(userId, response.data.id))
      dispatch(receiveItem(response))
      return response.data
    }
    dispatch(failed(response))
  } catch (error) {
    dispatch(failed(error))
  }
}

const get = (params = {}) => async (dispatch, getState) => {
  const { filter } = getState().productStore
  dispatch(fetch())
  await call({
    dispatch,
    fetch,
    api: () => findAll({
      active: 1,
      ...filter,
      ...params
    }),
    receive,
    failed
  })
}

const changeFilter = (params) => async (dispatch, getState) => {
  const { filter } = getState().productStore
  const newFilter = {
    ...filter,
    ...params
  }
  const {
    active,
    type,
    pageSize,
    ...other
  } = newFilter
  Router.push({
    pathname: '/product/list',
    query: other
  })
  if (newFilter && newFilter.q) {
    dispatch(change('SearchProduct', 'q', newFilter.q))
  }

  dispatch(updateState({
    filter: newFilter
  }))
  dispatch(get(newFilter))
}

const openProductDetail = (id) => {
  Router.push({
    pathname: '/product-detail',
    query: { id }
  })
}

const editQuantityProduct = (data, product) => async (dispatch) => {
  try {
    const response = await apiShoppingCartAddItem(data)
    if (response && response.success) {
      dispatch(cleanCurrentItem())
      dispatch(editQuantity(data, product))
    }
  } catch (error) {
    dispatch(failed(error))
  }
}

export {
  getFilter,
  get,
  updateState,
  changeFilter,
  openProductDetail,
  editQuantityProduct,
  getSearchProduct,
  getProductByCode
}
