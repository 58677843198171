import {
  setFavourite,
  getAllFavourite,
  getProductStatus
} from 'services/product/userFavService'
import {
  apiShoppingCartAddItem
} from 'services/shoppingCart/shoppingCartService'
import { call } from 'utils/yield'
import {
  getMyData
} from 'services/auth/authService'
import {
  FETCH_USERFAV,
  RECEIVE_LIST_USERFAV,
  RECEIVE_CURRENT_ITEM_USERFAV,
  EDIT_QUANTITY_ITEM_USERFAV,
  FAILED_USERFAV,
  SUCCESS_SET_FAVOURITE,
  UPDATE_STATE_USERFAV
} from '../types'

const fetch = () => {
  return {
    type: FETCH_USERFAV
  }
}

const receive = (response) => {
  return {
    type: RECEIVE_LIST_USERFAV,
    payload: {
      list: response && response.data && response.data.length > 0 ? response.data : [],
      meta: response && response.meta ? response.meta : []
    }
  }
}

const updateState = (payload) => {
  return {
    type: UPDATE_STATE_USERFAV,
    payload
  }
}

const success = (meta) => {
  return {
    type: SUCCESS_SET_FAVOURITE,
    payload: {
      meta
    }
  }
}

const receiveItem = (response) => {
  return {
    type: RECEIVE_CURRENT_ITEM_USERFAV,
    payload: {
      currentItem: response && response.data ? response.data : {}
    }
  }
}

const editQuantity = (response) => {
  return {
    type: EDIT_QUANTITY_ITEM_USERFAV,
    payload: {
      data: response || {}
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_USERFAV,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const getUserFavourite = (userId, lastId) => async (dispatch) => {
  dispatch(fetch())
  if (userId === null) {
    const responseUser = await getMyData()
    if (responseUser && responseUser.success) {
      userId = responseUser.data.id
    } else {
      dispatch(failed(responseUser))
    }
  } else {
    dispatch(updateState({
      loading: false
    }))
  }
  await call({
    dispatch,
    fetch,
    api: () => getAllFavourite(userId, lastId),
    receive,
    failed
  })
}

const getProductStat = (userId, productId) => async (dispatch) => {
  dispatch(fetch())
  try {
    if (userId === null) {
      const responseUser = await getMyData()
      if (responseUser && responseUser.success) {
        userId = responseUser.data.id
      } else {
        dispatch(failed(responseUser))
      }
    }
    const response = await getProductStatus(userId, productId)
    if (response && response.success) {
      dispatch(receiveItem(response))
    }
    dispatch(failed(response))
  } catch (error) {
    dispatch(failed(error))
  }
}

const setFavouriteProduct = (data) => async (dispatch) => {
  dispatch(fetch())
  try {
    const response = await setFavourite(data)
    if (response && response.success) {
      dispatch(success(response.data, response.meta))
      dispatch(getProductStat(data.userId, data.productId))
      return response.data
    }
    dispatch(failed(response))
    return response
  } catch (error) {
    dispatch(failed(error))
  }
}

const editQuantityProduct = (data) => async (dispatch) => {
  try {
    const response = await apiShoppingCartAddItem(data)
    if (response && response.success) {
      dispatch(editQuantity(data))
    }
  } catch (error) {
    dispatch(failed(error))
  }
}

export {
  getUserFavourite,
  setFavouriteProduct,
  getProductStat,
  editQuantityProduct,
  updateState
}
