import {
  getWeeklyTrend
} from 'services/home/homeService'
import {
  FETCH_HOME_WEEKLY_TREND,
  RECEIVE_HOME_WEEKLY_TREND,
  EDIT_QUANTITY_WEEKLY_TREND,
  FAILED_HOME_WEEKLY_TREND
} from '../types'

const fetch = () => {
  return {
    type: FETCH_HOME_WEEKLY_TREND
  }
}

const receive = (list, meta = {}) => {
  return {
    type: RECEIVE_HOME_WEEKLY_TREND,
    payload: {
      list,
      meta
    }
  }
}

const editQty = (response) => {
  return {
    type: EDIT_QUANTITY_WEEKLY_TREND,
    payload: {
      item: response
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_HOME_WEEKLY_TREND,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const get = (params) => async (dispatch) => {
  dispatch(fetch())
  const response = await getWeeklyTrend({
    ...params,
    page: 1
  })
  if (response && response.success && response.data && response.data.length > 0) {
    dispatch(receive(response.data, response.meta))
    return response.data
  }
  dispatch(failed(response))
  return []
}

const homeWeeklyEditQty = (params) => async (dispatch) => {
  try {
    dispatch(editQty(params))
  } catch (error) {
    dispatch(failed(error))
  }
}

export {
  get,
  receive,
  homeWeeklyEditQty
}
