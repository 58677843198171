import {
  apiProduct,
  apiProductCode
} from 'utils/config'
import request from 'utils/request'

const searchProductWithPagination = async (params) => {
  return request({
    url: '/search-product/get-limited',
    auth: true,
    data: params,
    method: 'get'
  })
}

const getById = async (id) => {
  return request({
    url: `${apiProduct}/${id}`,
    auth: true,
    method: 'get'
  })
}

const getByProductCode = async (productCode, idToken) => {
  return request({
    url: `${apiProductCode}/${productCode}`,
    idToken,
    auth: true,
    method: 'get'
  })
}

const findAll = async (data) => {
  return request({
    url: `${apiProduct}?device=web`,
    auth: true,
    data,
    method: 'get'
  })
}

export {
  searchProductWithPagination,
  findAll,
  getById,
  getByProductCode
}
