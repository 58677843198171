import {
  getProductSignature
} from 'services/home/homeService'
import {
  FETCH_HOME_PRODUCT_SIGNATURE,
  RECEIVE_HOME_PRODUCT_SIGNATURE,
  EDIT_QUANTITY_PRODUCT_SIGNATURE,
  UPDATE_STATE_PRODUCT_SIGNATURE,
  FAILED_HOME_PRODUCT_SIGNATURE
} from '../types'

const fetch = () => {
  return {
    type: FETCH_HOME_PRODUCT_SIGNATURE
  }
}

const receive = (list, meta = {}) => {
  return {
    type: RECEIVE_HOME_PRODUCT_SIGNATURE,
    payload: {
      list,
      meta
    }
  }
}

const updateState = (payload) => {
  return {
    type: UPDATE_STATE_PRODUCT_SIGNATURE,
    payload
  }
}

const editQty = (response) => {
  return {
    type: EDIT_QUANTITY_PRODUCT_SIGNATURE,
    payload: {
      item: response
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_HOME_PRODUCT_SIGNATURE,
    payload: {
      data: error.data,
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const get = (params) => async (dispatch) => {
  dispatch(fetch())
  const response = await getProductSignature({
    ...params,
    page: 1
  })
  if (response && response.success && response.data && response.data.length > 0) {
    dispatch(receive(response.data, response.meta))
  } else {
    dispatch(failed(response))
  }
}

const productSignatureEditQty = (params) => async (dispatch) => {
  try {
    dispatch(editQty(params))
  } catch (error) {
    dispatch(failed(error))
  }
}

export {
  get,
  receive,
  productSignatureEditQty,
  updateState
}
