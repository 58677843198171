import {
  apiUserFav
} from 'utils/config'
import request from 'utils/request'

const getProductStatus = async (userId, productId) => {
  return request({
    url: `${apiUserFav}/get/${userId}/${productId}`,
    auth: true,
    method: 'get'
  })
}

const getAllFavourite = async (userId, lastId) => {
  return request({
    url: `${apiUserFav}/get/${userId}`,
    auth: true,
    data: { lastId },
    method: 'get'
  })
}

const setFavourite = async (data) => {
  return request({
    url: `${apiUserFav}/add?device=web`,
    auth: true,
    data,
    method: 'post'
  })
}

export {
  setFavourite,
  getAllFavourite,
  getProductStatus
}
