import {
  getBuyAgain
} from 'services/home/homeService'
import { getUserToken } from 'utils/storage'
import {
  FETCH_HOME_BUY_AGAIN,
  RECEIVE_HOME_BUY_AGAIN,
  EDIT_QUANTITY_BUY_AGAIN,
  FAILED_HOME_BUY_AGAIN
} from '../types'

const fetch = () => {
  return {
    type: FETCH_HOME_BUY_AGAIN
  }
}

const receive = (list, meta = {}) => {
  return {
    type: RECEIVE_HOME_BUY_AGAIN,
    payload: {
      list,
      meta
    }
  }
}

const editQty = (response) => {
  return {
    type: EDIT_QUANTITY_BUY_AGAIN,
    payload: {
      item: response
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_HOME_BUY_AGAIN,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const get = (params) => async (dispatch) => {
  const token = await getUserToken()
  if (!token) {
    return
  }
  dispatch(fetch())
  const response = await getBuyAgain({
    ...params,
    page: 1
  })
  if (response && response.success && response.data && response.data.length > 0) {
    dispatch(receive(response.data, response.meta))
  } else {
    dispatch(failed(response))
  }
}

const homeBuyAgainEditQty = (params) => async (dispatch) => {
  try {
    dispatch(editQty(params))
  } catch (error) {
    dispatch(failed(error))
  }
}

export {
  get,
  homeBuyAgainEditQty
}
