import request from 'utils/request'

const apiShoppingCart = async () => {
  return request({
    url: '/shopping-cart/get',
    auth: false,
    method: 'get'
  })
}

const apiShoppingCartAdd = async () => {
  return request({
    url: '/shopping-cart/add?device=web',
    auth: true,
    method: 'post'
  })
}

const apiShoppingCartAddItem = async (data) => {
  return request({
    url: '/shopping-cart-detail/add?device=web',
    auth: true,
    data,
    method: 'post'
  })
}

const apiShoppingCartAddMemo = async (data) => {
  return request({
    url: '/shopping-cart-detail/edit-memo',
    auth: true,
    data,
    method: 'put'
  })
}

const apiShoppingCartDetailProduct = async (transId, productId) => {
  return request({
    url: '/shopping-cart-detail/product',
    auth: false,
    data: {
      transId,
      productId
    },
    method: 'get'
  })
}

export {
  apiShoppingCart,
  apiShoppingCartAdd,
  apiShoppingCartAddItem,
  apiShoppingCartDetailProduct,
  apiShoppingCartAddMemo
}
